<template>
  <div class="theme_one_block">
    <div class="theme_one_header_block">
      <div class="theme_one_header_container">
        <div class="theme_one_header_label">
          Финансовая грамотность и как ее повысить?
        </div>
      </div>
    </div>
    <div class="theme_one_main_block">
      <div class="theme_one_main_container">
        <div class="theme_one_content_container">
          <a class="theme_one_content_container_btn_back">
            <div class="theme_one_content_container_btn_text">Назад</div>
          </a>
          <div class="theme_one_plot">
            <div class="theme_one_exercises_plot_theme_active">
              <div class="theme_one_plot_theme_active_label">Тема 1</div>
              <div class="theme_one_plot_theme_active_inactive_article">Советы по изучению языка</div>
              <div class="theme_one_plot_theme_active_inactive_video">Особенности диалектов в ...</div>
              <div class="theme_one_plot_theme_active_inactive_slide">Произношение отдельных...</div>
              <div class="theme_one_plot_theme_active_selected">Упражнения</div>
            </div>
            <a href="#" class="theme_one_plot_theme">
              Тема 2
            </a>
            <a href="#" class="theme_one_plot_theme">
              Тема 3
            </a>
            <a href="#" class="theme_one_plot_theme">
              Тема 4
            </a>
            <a href="#" class="theme_one_plot_theme">
              Тестирование
            </a>
          </div>
        </div>
        <div class="theme_one_exercises_desc">
          <div class="theme_one_exercises_desc_text_container">
            <div class="theme_one_desc_title">
              Упражнение 4
            </div>
            <div class="theme_one_desc_test">
              <div v-show="showModalGoodResult" class="theme_one_modal_result_good">
                <div class="theme_one_modal_result_text">Ответ успешно сохранен</div>
                <div class="theme_one_modal_result_img"/>
              </div>
              <div class="theme_one_desc_text">
                Пятеро школьников приняли участие в забеге. Али быстрее чем Берик и Думан, и Берик медленнее Думана.
                Тимур
                быстрее Али, но медленнее Каната. Определите, кто станет четвертым в этой гонке.
              </div>
              <div class="my_courses_list_test_radio_block">
                <div class="my_courses_radio_item">
                  <input type="radio" name="filter" id="8" class="my_courses_list_radio"/>
                  <label for="8" class="my_courses_radio_label">Берик</label>
                </div>
                <div class="my_courses_radio_item">
                  <input type="radio" name="filter" id="36" class="my_courses_list_radio"/>
                  <label for="36" class="my_courses_radio_label">Думан</label>
                </div>
                <div class="my_courses_radio_item">
                  <input type="radio" name="filter" id="40" class="my_courses_list_radio"/>
                  <label for="40" class="my_courses_radio_label">Тимур</label>
                </div>
                <div class="my_courses_radio_item">
                  <input type="radio" name="filter" id="72" class="my_courses_list_radio"/>
                  <label for="72" class="my_courses_radio_label">Канат</label>
                </div>
              </div>
            </div>
            <div class="theme_one_desc_btn">
              <a href="#" class="theme_one_desc_btn_back" @click="showModalGoodResult = !showModalGoodResult">Ответ сохранен</a>
              <a href="#" class="theme_one_desc_btn_next">Далее</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThemeOne",
  data() {
    return {
      showModalGoodResult: false,
    }
  }
}
</script>

<style scoped>

</style>